@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

.svgChart {
  margin-bottom: -9px;

  rect {
    fill: $primary-color;
  }
}

.slider {
  position: relative;
  height: 10px;
  margin-bottom: 15px;

  :global {
    .rc-slider {
      position: relative;
      padding: 4px 0;
      width: 100%;
      -ms-touch-action: none;
      touch-action: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider-rail {
      height: 2px;
      background-color: $dark-grey;
      position: absolute;
      width: 100%;
    }
    .rc-slider-track {
      position: absolute;
      left: 0;
      height: 2px;
      background-color: $primary-color-light;
    }
    .rc-slider-handle-1 {
      margin-left: -4px;
    }

    .rc-slider-handle {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      margin-top: -4px;

      &:after {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        background-color: $primary-color;
        border-radius: 50%;
        border: 1px solid $grey;
        transition: all 0.1s ease-in-out;
      }

      &:hover,
      &.dragging {
        &:after {
          transform: scale(1.6);
        }
        .tooltip {
          top: -30px;
        }
      }
    }
    .rc-slider-handle-2 {
      margin-left: -6px;
    }
  }
}

.tooltip {
  position: absolute;
  top: -24px;
  padding: 3px;
  background-color: $default-text;
  color: $white;
  text-align: center;
  font-size: 12px;
  line-height: 1;
  // center tooltip vertically with handle
  transform: translateX(-50%);
  // adjust by half of the handle size
  margin-left: 5px;

  &:before {
    display: block;
    content: "";
    width: 4px;
    height: 4px;
    background-color: $default-text;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: rotateZ(45deg);
    margin-left: -2px;
  }
}

.priceRange {
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 100%;

  label {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  span {
    flex: 0 0 auto;
    margin: 0 10px;

    &:first-child {
      margin: 0 10px 0 0;
    }
  }

  input {
    flex: 1 1 100%;
    padding: 11px;
    -moz-appearance: textfield;
    border: none;
    border-radius: 4px;
    min-width: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  span,
  input {
    font-size: 16px;
    line-height: 23px;
    color: $dark-grey;
  }
}

.priceRangesList {
  margin-bottom: 15px;

  li {
    list-style: none;
  }
}

.reset {
  display: none;
  text-align: right;
  margin-top: 5px;

  a {
    text-decoration: underline;
    cursor: pointer;
    color: $primary-color;
    &:hover {
      color: $primary-color-light;
    }
  }
}

.clearFilters {
  color: $primary-color;
  cursor: pointer;
  font-stretch: condensed;
  font-weight: bold;
  margin-right: 10px;
  text-decoration: underline;

  &:hover {
    color: $primary-color-light;
  }

  @media (min-width: 1025px) {
    display: none;
  }
}

.itemText {
  align-items: center;
  flex: 1;
  padding-right: 5px;
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;

  .name {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  a {
    color: $dark-grey;
    margin-left: 5px;
    display: none;

    &:hover {
      color: var(--color_secondary);
    }
  }

  //&:hover.has-url {
  &:hover {
    color: var(--color_primary_hover);
    text-decoration: underline;
    .name {
      padding-right: 15px;
    }
    a {
      margin-left: -10px;
      display: flex;
    }
  }
}

.itemAmount {
  color: $dark-grey;
  font-size: 0.75rem;
}
