@import "../styleguide/colors.scss";

.propertySize {
  padding: 7.5px;
  width: 71px;
  height: 55px;
  position: relative;
  cursor: pointer;

  > div {
    background-color: $background;
    width: 56px;
    height: 40px;
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover:not(.selected) {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    }

    &.selected {
      border: 1px solid;
      border-color: $primary-color;

      &:before {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        background-color: $primary-color;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0id2hpdGUiIGQ9Ik0yMC4yODUgMmwtMTEuMjg1IDExLjU2Ny01LjI4Ni01LjAxMS0zLjcxNCAzLjcxNiA5IDguNzI4IDE1LTE1LjI4NXoiLz48L3N2Zz4=);
        background-size: 8px 8px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px;
        position: absolute;
        top: -8px;
        left: -8px;
      }
    }
  }
}
