@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

.root {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  padding-bottom: 20px;
  padding-top: 15px;
  z-index: 1;
}

.filterPills {
  width: 100%;
}

.activeFiltersContainer {
  display: flex;
  flex: 0 0 auto;
  margin: 15px 0;
}

.activeFilters {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  list-style-type: none;
}

.activeFilterPill {
  align-items: center;
  background-color: $primary-color;
  border-radius: 4px;
  color: $white!important;
  cursor: pointer;
  display: flex;
  font-size: 0.813rem;
  height: 25px;
  justify-content: space-between;
  line-height: 1.563rem;
  margin: 0 5px 5px 0;
  min-width: 0;
  padding: 0 10px;
  text-decoration: none;

  &Text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &Cross {
    align-items: center;
    color: $white;
    display: flex;
    font-size: 1rem;
    height: 100%;
    margin-left: 10px;
    text-decoration: none;
  }
}

.clearAllFilters {
  align-items: center;
  border-left: solid 1px $grey;
  display: flex;
  flex: 0 0 auto;
  margin-left: 10px;
  padding-left: 10px;
}

.clearAllFiltersCrossText {
  display: none;

  @media screen and (min-width: $medium) {
    display: inline-block;
  }
}

.clearAllFiltersCross {
  align-items: center;
  display: flex;
  color: $secondary-text;
  text-decoration: none;
}

.clearAllFiltersCrossIcon {
  align-items: center;
  background: $secondary-text;
  border-radius: 50%;
  color: $white;
  display: flex;
  flex: 0 0 auto;
  font-size: 1.2rem;
  height: 16px;
  justify-content: center;
  margin-right: 5px;
  width: 16px;
}

.rowItems {
  display: flex;
  flex-wrap: wrap;
}

.divider {
  background: var(--grey);
  display: none;
  height: 1px;
  margin: 15px 0;
  flex: 0 0 100%;
  @media screen and (min-width: $medium) {
    display: block;
    order: 2;
  }
}

.filtersBtnContainer {
  margin-top: 30px;
  order: 1;
  flex: 0 0 100%;
  @media screen and (min-width: $medium) {
    margin-top: 0;
    flex: 0 0 33%;
    order: 2;
  }

  @media screen and (min-width: $extra-large) {
    display: none;
  }
}

.filtersIcon {
  margin-right: 10px;
}

.resultsCounter {
  align-items: center;
  margin: 10px 0 0;
  order: 2;
  flex: 0 0 50%;

  @media screen and (min-width: $medium) {
    margin-top: 30px;
    text-align: right;
    order: 1;
    flex: 0 0 100%;
  }

  @media screen and (min-width: $extra-large) {
    margin-top: 0;
  }
}

.sortingOptions {
  align-items: center;
  display: none;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (min-width: $extra-large) {
    display: flex;
    margin-top: 10px;
  }
}

.sortingAndView {
  display: flex;
  justify-content: flex-end;
  order: 2;
  flex: 0 0 50%;

  @media screen and (min-width: $medium) {
    flex: 0 0 67%;
  }     
  
  @media screen and (min-width: $large) {
    justify-content: space-between;
    flex: 0 0 100%;
  }     
}

.sortingOption {
  align-items: center;
  color: $secondary-text;
  display: flex;
  text-decoration: none;

  &:focus,
  &:hover {
    color: $primary-color;
  }
}

.sortingOptionSelected {
  color: $primary-color;
  font-stretch: condensed;
  font-weight: bold;
}

.sortingOptionDirectionIcon {
  margin-left: 5px;
}

.searchTermHeading {
  color: $secondary-text;
  margin-bottom: 15px;

  span > span {
    font-stretch: condensed;
    font-weight: 700;
  }
}

.viewOptions {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: flex-end;
  list-style-type: none;
  margin: 10px 0 0;
  padding: 0;

  li:first-child {
    margin-right: 10px;
  }
}

.viewOption {
  color: $secondary-text;
  text-decoration: none;

  &:focus,
  &:hover {
    color: $primary-color;
  }
}

.viewOptionSelected {
  color: $primary-color;
  font-stretch: condensed;
  font-weight: bold;
}

.filters {
  display: none;

  @media screen and (min-width: $extra-large) {
    display: flex;
    flex-flow: column;
    width: 25%;
    padding-right: 30px;
    position: sticky;
    top: -150%;
    z-index: 3;
  }

  @media screen and (min-width: $extra-extra-large) {
    width: 16%;
  }
}

main {
  width: 100%;
  @media screen and (min-width: $extra-large) {
    width: 75%;
  }

  @media screen and (min-width: $extra-extra-large) {
    width: 84%;
  }
}

.filtersRootCategories {
  list-style-type: none;
  margin-left: 15px;
  padding: 0;

  li {
    display: flex;
    flex-flow: column;
    flex: 100%;
  }

  a {
    align-items: center;
    color: $default-text;
    display: flex;
    flex: 100%;
    flex-flow: row nowrap;
    padding: 5px 0;
    text-decoration: none;

    &.filterCategorySelected {
      color: $primary-color;
    }

    &:hover {
      color: $primary-color;
      text-decoration: underline;

      .filtersCategoryDocsCount {
        color: $primary-color;
      }
    }
  }
}

.filtersCategoryName {
  flex: 1 1 100%;
}

.filtersCategoryDocsCount {
  color: $dark-grey;
  flex: auto;
  font-size: 0.75rem;
}

.filtersSubCategories {
  margin-left: 20px;
}

.filtersSubSubCategories {
  margin-left: 20px;
}

.filtersAccordion {
  height: 1.375em;
  margin-top: 30px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  > span {
    cursor: pointer;

    &:after {
      content: "+";
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &.filtersAccordionOpen {
    height: auto;
    overflow: visible;

    > span:after {
      content: "-";
    }
  }
}

.filtersAccordion:after,
.filtersTitle {
  display: block;
  font-stretch: condensed;
  font-weight: bold;
  margin-bottom: 15px;
}

.filtersTitle {
  margin-bottom: 0;
}

.filterPriceTitle {
  margin-bottom: 0;
}

.filtersCheckboxLabel {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  height: 30px;
}

.paginationAndSummaryContainer {
  display: flex;
  align-items: center;
}

.summary {
  margin-right: 30px;
  display: none;

  @media screen and (min-width: $medium) {
    display: flex;
  }
}

.pagination {
  margin-left: auto;
}