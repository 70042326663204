@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

.root {
  column-gap: 14px;
  list-style-type: none;
  max-height: 234px;
  overflow: auto;
  padding: 7px;
  row-gap: 15px;
}

.option {
  align-items: center;
  background-color: $white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-size: 0.75em;
  height: 40px;
  justify-content: center;
  outline: none;
  position: relative;
  width: 100%;

  &.optionSelected,
  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  }

  &.optionSelected {
    border: 1px solid $primary-color;
  }
}

.optionSelectedIconContainer {
  background-color: $primary-color;
  border-radius: 50%;
  color: $white;
  font-size: 0.55rem;
  left: -7px;
  position: absolute;
  height: 16px;
  width: 16px;
  top: -7px;
}

.optionScreenReader {
  opacity: 0;
}
