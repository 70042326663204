@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

.root {
  position: relative;

  &.rootOpen {
    .trigger {
      color: $primary-color;
    }

    .values {
      margin-top: 15px;
      transform: scale(1, 1);
    }

    svg {
      transform: rotate(180deg);
      transform-origin: center center;
    }

    :global {
      .pageSizeValuePart {
        font-stretch: condensed;
        font-weight: bold;
      }

      a .pageSizeValuePart {
        font-weight: normal;
        font-stretch: normal;
      }
    }
  }

  .trigger {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    outline: none;

    svg {
      margin-left: 5px;
      transition: transform 250ms;
    }
  }

  .values {
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-flow: column;
    left: 0;
    list-style-type: none;
    padding: 0 1px;
    position: absolute;
    transform: scale(1, 0);
    transform-origin: top center;
    transition: transform 0.17s ease;
    width: 100%;
  }

  .value {
    display: flex;

    &:first-child:hover::before {
      border-bottom: 10px solid $primary-color;
    }

    a {
      color: $dark-grey;
      font-size: 0.875em;
      padding: 10px 15px;
      text-decoration: none;
      width: 100%;
    }

    &:first-child {
      &:before {
        display: block;
        border-bottom: 10px solid $white;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: "";
        height: 0;
        position: absolute;
        top: -8px;
        right: 10px;
        width: 0;
      }

      a {
        border-radius: 4px 4px 0 0;
      }
    }

    &:last-child {
      a {
        border-radius: 0 0 4px 4px;
      }
    }
  }

  &.valueSelected a,
  a:hover {
    background-color: $primary-color;
    color: $white;
  }
}
