@font-face {
  font-family: "TT Prosto Sans Condensed";
  src: url("/fonts/TTProstoSansCondensed-Light.eot") format("eot"),
    url("/fonts/TTProstoSansCondensed-Light.woff2") format("woff2"),
    url("/fonts/TTProstoSansCondensed-Light.woff") format("woff");
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: "TT Prosto Sans Condensed";
  src: url("/fonts/TTProstoSansCondensed-Regular.eot") format("eot"),
    url("/fonts/TTProstoSansCondensed-Regular.woff2") format("woff2"),
    url("/fonts/TTProstoSansCondensed-Regular.woff") format("woff");
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: "TT Prosto Sans Condensed";
  src: url("/fonts/TTProstoSansCondensed-Bold.eot") format("eot"),
    url("/fonts/TTProstoSansCondensed-Bold.woff2") format("woff2"),
    url("/fonts/TTProstoSansCondensed-Bold.woff") format("woff");
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
  font-weight: bold;
}
