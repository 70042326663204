@import "../styleguide/colors.scss";

.checkboxContainer {
  display: flex;
  position: relative;
}

.checkboxContainer input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkboxButton {
  background-color: white;
  border-radius: 4px;
  border: 2px solid $grey;
  box-sizing: border-box;
  flex: 0 0 auto;
  height: 20px;
  margin-right: 8px;
  position: relative;
  width: 20px;
}

.checkboxButton:hover {
  border: 2px solid $primary-color;
}

.checkboxButton:active {
  border: 2px solid $primary-color-dark;
  background-color: $primary-color-dark;
}

.disabled .checkboxButton {
  background-color: $grey;
}

.valid .checkboxButton {
  border: 2px solid $succes;
}

.invalid .checkboxButton {
  border: 2px solid $error;
}

.checkboxContainer:hover {
  color: $primary-color;
}

.checkboxContainer:active {
  color: $primary-color-dark;
}

.checkboxContainer:hover .checkboxButton {
  border: 2px solid $primary-color;
}

.checkboxContainer:active .checkboxButton {
  border: 2px solid $primary-color-dark;
}

.checkboxButton:after,
.checkboxButton:before {
  content: "";
  position: absolute;
  display: none;
}

.checkboxContainer input:checked ~ .checkboxButton:after {
  display: block;
  transform: rotate(45deg);
  width: 6px;
  height: 2px;
  background-color: $white;
  left: 2px;
  top: 9px;
}

.checkboxContainer input:checked ~ .checkboxButton:before {
  display: block;
  transform: rotate(45deg);
  width: 2px;
  height: 9px;
  background-color: $white;
  left: 8px;
  top: 4px;
}

.disabled {
  color: $grey;
}

.valid {
  color: $succes;
}

.invalid {
  color: $error;
}

.valid .checkboxButton:hover {
  border: 2px solid $succes;
  color: $succes;
}

.disabled input,
.disabled .checkboxButton:hover,
.disabled .checkboxButton:active {
  background-color: $grey;
  border: 2px solid $grey;
  pointer-events: none;
}

.disabled:hover {
  color: $grey;
}

.disabled:hover .checkboxButton {
  border: 2px solid $grey;
}

.valid:hover,
.valid:active {
  color: $succes;
}

.valid:hover .checkboxButton {
  border: 2px solid $succes;
}

.invalid:hover,
.invalid:active {
  color: $error;
}

.invalid:hover .checkboxButton {
  border: 2px solid $error;
}

.invalid .checkboxButton:hover {
  border: 2px solid $error;
  color: $error;
}

.valid .checkboxButton:active,
.invalid .checkboxButton:active {
  background-color: $white;
}

.checkboxContainer input:checked ~ .checkboxButton {
  border: 2px solid $primary-color;
  background-color: $primary-color;
}

.valid input:checked ~ .checkboxButton {
  border: 2px solid $succes;
  background-color: $succes;
}

.invalid input:checked ~ .checkboxButton {
  border: 2px solid $error;
  background-color: $error;
}

.disabled input:checked ~ .checkboxButton {
  background-color: $grey;
  border: 2px solid $grey;
}

.disabled input:checked ~ .checkboxButton:after,
.disabled input:checked ~ .checkboxButton:before {
  background-color: $dark-grey;
}
