@import './vars.scss';

@mixin media($breakpoint, $maxWidth) {
  @if $breakpoint == xs {
    @content;
  } @else {
    @media screen and (min-width: #{$maxWidth}) {
      @content;
    }
  }
}

@mixin col-x($breakpoint, $maxWidth) {
  @include media($breakpoint, $maxWidth) {
    @for $i from 1 through 12 {
      @for $y from 1 through 12 {
        $combined: $i + $y;
        @if ($combined <= 12) {
          .offset-#{$y}-#{$breakpoint}.col-#{$i}-#{$breakpoint} {
            grid-column: #{$y + 1} / span #{$i};
          }
        }
      }
      .col-#{$i}-#{$breakpoint} {
        grid-column-start: span $i;
        max-width: 100%;
      }
    }
  }
}

.container {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;

  @media screen and (min-width: $small) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media screen and (min-width: $large) {
    max-width: $large;
  }

  @media screen and (min-width: $extra-large) {
    max-width: $extra-large;
  }

  @media screen and (min-width: $extra-extra-large) {
    max-width: $extra-extra-large;
    margin: 0 auto;
  }
}

.siab-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 20px;

  @media screen and (min-width: $small) {
    column-gap: 30px;
  }
}

@include col-x(xs, $extra-small);
@include col-x(s, $small);
@include col-x(m, $medium);
@include col-x(l, $large);
@include col-x(xl, $extra-large);
@include col-x(xxl, $extra-extra-large);
