@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

.root {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  
  &.viewOptionTiles {
    @media screen and (min-width: $extra-extra-large) {
      grid-template-columns: repeat(15, 1fr);
    }
  }
}

@media screen and (min-width: $medium) and (max-width: #{$extra-extra-large - 1}) {
  /** First 3 cards */
  .productCard:nth-of-type(-n + 3) {
    order: 1;
  }

  .productCard:nth-of-type(-n + 3) ~ .productDetailsCard {
    order: 2;
  }

  /** All next cards in groups of 3 */
  /** 60 rows = 180 records divided by 3 columns */
  @for $row from 2 through 60 {
    .productCard:nth-of-type(-n + #{$row * 3}):not(:nth-of-type(-n
          + #{($row - 1)
          *
          3})) {
      order: $row + ($row - 1);
    }

    .productCard:nth-of-type(-n + #{$row * 3}):not(:nth-of-type(-n
          + #{($row - 1)
          *
          3}))
      ~ .productDetailsCard {
      order: ($row + ($row - 1)) + 1;
    }
  }
}

@media screen and (min-width: $extra-extra-large) {
  .productDetailsCard {
    grid-column-start: span 15;
  }

  /** First 5 cards */
  .productCard:nth-of-type(-n + 5) {
    order: 1;
  }

  .productCard:nth-of-type(-n + 5) ~ .productDetailsCard {
    order: 2;
  }

  /** All next cards in groups of 5 */
  @for $row from 2 through 36 {
    .productCard:nth-of-type(-n + #{$row * 5}):not(:nth-of-type(-n
          + #{($row - 1)
          *
          5})) {
      order: $row + ($row - 1);
    }

    .productCard:nth-of-type(-n + #{$row * 5}):not(:nth-of-type(-n
          + #{($row - 1)
          *
          5}))
      ~ .productDetailsCard {
      order: ($row + ($row - 1)) + 1;
    }
  }
}

.viewOptionsColumns {
  @media screen and (min-width: $medium) and (max-width: #{$extra-extra-large - 1}) {
    /** First 5 cards */
    .productCard:nth-of-type(-n + 2) {
      order: 1;
    }

    .productCard:nth-of-type(-n + 2) ~ .productDetailsCard {
      order: 2;
    }

    /** All next cards in groups of 2 */
    /** 90 rows = 180 records divided by 2 columns */
    @for $row from 2 through 90 {
      .productCard:nth-of-type(-n + #{$row * 2}):not(:nth-of-type(-n
            + #{($row - 1)
            *
            2})) {
        order: $row + ($row - 1);
      }

      .productCard:nth-of-type(-n + #{$row * 2}):not(:nth-of-type(-n
            + #{($row - 1)
            *
            2}))
        ~ .productDetailsCard {
        order: ($row + ($row - 1)) + 1;
      }
    }
  }

  @media screen and (min-width: $extra-extra-large) {
    .productDetailsCard {
      grid-column-start: span 12;
    }

    /** First 5 cards */
    .productCard:nth-of-type(-n + 2) {
      order: 1;
    }

    .productCard:nth-of-type(-n + 2) ~ .productDetailsCard {
      order: 2;
    }

    /** All next cards in groups of 2 */
    @for $row from 2 through 90 {
      .productCard:nth-of-type(-n + #{$row * 2}):not(:nth-of-type(-n
            + #{($row - 1)
            *
            2})) {
        order: $row + ($row - 1);
      }

      .productCard:nth-of-type(-n + #{$row * 2}):not(:nth-of-type(-n
            + #{($row - 1)
            *
            2}))
        ~ .productDetailsCard {
        order: ($row + ($row - 1)) + 1;
      }
    }
  }
}
