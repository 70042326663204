@import "../styleguide/colors.scss";

.root {
  display: flex;
  flex-flow: column;
}

.label {
  flex: 0 0 auto;
  font-weight: bold;
  font-stretch: condensed;
  width: 100%;
}

.icon {
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: absolute;
  top: 0;

  svg {
    fill: currentColor;
  }
}

.input {
  align-items: center;
  background-color: $white;
  background-image: none;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 48px;
  justify-content: center;
  outline: 0;
  padding: 0 16px;
  width: 100%;

  .icon {
    color: $primary-color-light;
  }

  &:focus {
    border: solid 2px $primary-color-light;
    outline: 0;
  }

  @media (hover: hover) {
    &:hover {
      border: solid 2px $primary-color-light;
      outline: 0;
    }
  }
}

.valid {
  .icon,
  .input {
    color: $succes;
  }

  .input {
    border: solid 2px $succes;

    &::placeholder {
      color: $succes;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:hover {
      -webkit-text-fill-color: $succes;
    }
  }
}

.invalid {
  .icon,
  .input {
    color: $error;
  }

  .input {
    border: solid 2px $error;

    &::placeholder {
      color: $error;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:hover {
      -webkit-text-fill-color: $error;
    }
  }
}

.disabled {
  .icon {
    color: $secondary-text;
  }

  .input {
    background-color: $grey;
  }
}

.inputContainer {
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  position: relative;
  width: 100%;

  &.left {
    .icon {
      left: 15px;
    }

    .input {
      padding-left: 35px;
    }
  }

  &.right {
    .icon {
      right: 15px;
    }

    .input {
      padding-right: 35px;
    }
  }
}
