@import "../styleguide/colors.scss";
@import "../styleguide/fonts.scss";

.buttonContainer {
  align-items: center;
  border: none;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  display: flex;
  font-family: "TT Prosto Sans Condensed Bold", sans-serif;
  min-height: 48px;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.iconLeft svg {
  margin-right: 8px;
}

.iconRight svg {
  margin-left: 8px;
}

.boxed svg {
  margin: 0;
}

.small {
  height: 36px;
}

.boxed {
  width: 48px;
}

.small.boxed {
  width: 36px;
}

.primary {
  background-color: $primary-color;

  &:focus,
  &:hover {
    background-color: $primary-color-dark;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  }
}

.secondary {
  background-color: $secondary-color;

  &:focus,
  &:hover {
    background-color: $secondary-color-dark;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  }
}

.white {
  background-color: $white;
  color: $primary-color;
}

.white:hover {
  background-color: $primary-color-dark;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
}

.primary.inverted {
  background-color: transparent;
  border: 2px solid $primary-color;
  color: $primary-color;
}

.primary.inverted:hover {
  border: 2px solid $primary-color-dark;
  color: $primary-color-dark;
}

.primary.trivial {
  background-color: transparent;
  color: $primary-color;
}

.primary.trivial:hover {
  color: $primary-color-dark;
  box-shadow: none;
  text-decoration: underline;
}

.secondary.trivial {
  background-color: transparent;
  color: $secondary-color;
}

.secondary.trivial:hover {
  color: $secondary-color-dark;
  box-shadow: none;
  text-decoration: underline;
}

.secondary.inverted {
  background-color: transparent;
  border: 2px solid $secondary-color;
  color: $secondary-color;
}

.secondary.inverted:hover {
  border: 2px solid $secondary-color-dark;
  color: $secondary-color-dark;
}

.white.inverted {
  background-color: transparent;
  border: 2px solid $white;
  color: $white;
}

.white.inverted:hover {
  border: 2px solid $primary-color-dark;
  color: $primary-color-dark;
}

.disabled {
  background-color: $grey;
  color: $secondary-text;
  cursor: default;
}

.disabled:hover {
  background-color: $grey;
  box-shadow: none;
  color: $secondary-text;
}

.disabled.inverted {
  background-color: transparent;
  border: 2px solid $grey;
  color: $grey;
}

.disabled.inverted:hover {
  background-color: transparent;
  border: 2px solid $grey;
  color: $grey;
}

.disabled.trivial {
  background-color: transparent;
  color: $grey;
}

.disabled.trivial:hover {
  background-color: transparent;
  color: $grey;
  text-decoration: none;
}
