@import "../styleguide/colors.scss";

.menu {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
  padding: 15px;
  position: absolute;
  right: 0;
  top: 80px;
  width: 360px;
  z-index: 1;
}
