.propertyCarousel {
  margin: 15px 0;
  display: flex;
}

.scrollButton {
  width: 32px;
  min-width: 32px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollButtonDisabled {
  opacity: 0.3;
  pointer-events: none;
}

.properties {
  overflow: hidden;
  & ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    transition: transform 250ms ease-in-out;
  }
}

.sizes {
  & ul {
    font-size: 12px;
    color: #4a4a4a;
  }
}
