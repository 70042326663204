@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

.filterShowAll {
  @media screen and (max-width: $extra-large) {
    min-height: 100vh;
  }

  padding-bottom: 85px;

  header {
    background: $background;
    border-bottom: 0;
    flex-wrap: wrap;
    left: 0;
    margin-bottom: 15px;
    padding-top: 5px;
    position: fixed;
    right: 0;
    z-index: 1;

    button {
      color: $default-text;
      font-size: 0.875rem;
      top: 15px;
    }
  }

  ul {
    margin-top: 100px;
  }

  .searchIconContainer {
    left: 0 !important;
  }
}

.root {
  @media screen and (max-width: $extra-large) {
    margin-top: 100px;
    margin-bottom: 75px;
  }

  list-style-type: none;
}

.searchBoxContainer {
  background: $background;
  border-bottom: solid 1px $dark-grey;
  flex: 0 0 100%;
  left: 0px;
  margin-bottom: 15px;
  padding: 0 15px;
  position: fixed;
  right: 0px;
  top: 40px;
  z-index: 1;
}

.searchBox {
  appearance: none;
  background: transparent;
  font-size: 1.125rem;
  padding-left: 25px;
  padding-right: 0;

  &:focus,
  &:hover {
    border: 0 !important;
  }
}

.searchIconContainer {
  font-size: 1.125rem;
}

.searchIcon {
  color: $primary-color;
}

.itemName {
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itemDocs {
  color: $dark-grey;
  flex: 0 0 auto;
  font-size: 0.75rem;
}

.toggleCategoriesBtn {
  align-items: center;
  background: none;
  border: none;
  color: $primary-color;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
  outline: none;
  width: auto;

  &:hover {
    text-decoration: underline;
  }
}

.toggleDirectionIcon {
  font-size: 0.875rem;
  margin-left: 5px;
}

.scroll {
  overflow: scroll;
}

.button:first-of-type {
  margin-right: 15px;
}

.footer {
  background: $background !important;
  border-top: 1px solid $grey;
  bottom: 0;
  left: 10px;
  padding: 15px 0;
  position: fixed;
  right: 10px;
}

.menu {
  margin-top: -15px;
  padding: 0;
  right: -30px;
  top: 0;
  transform: translateX(100%);
  width: 500px;
}

.filterShowAllMenu {
  position: relative;
  z-index: 1;

  header {
    align-items: center;
    background-color: $background;
    display: flex;
    padding: 15px;

    button {
      background-color: initial;
      border: none;
      height: 16px;
      position: absolute;
      right: 15px;
    }
  }

  .searchBoxContainer {
    padding: 0;
    position: initial;
  }

  .scrollContainer {
    background: $white;
    height: 350px;
    padding-left: 15px;
  }

  .searchIcon {
    left: 15px !important;
  }

  .searchBox {
    background-color: $white;
    padding-left: 50px !important;
  }

  ul {
    columns: 2;
  }

  .footer {
    display: flex;
    padding: 15px;
    position: initial;
  }

  .button {
    width: 50%;
  }
}

.gridItem:nth-child(even) {
  @media screen and (min-width: $extra-large) {
    padding-left: 15px;
  }
}

.gridItem:last-child {
  @media screen and (max-width: $extra-large) {
    margin-bottom: 10px;
  }
}
