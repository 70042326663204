@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

.root {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: -100%;
  opacity: 0;
  position: fixed;
  top: 0;
  transform: translateX(0);
  pointer-events: auto;
  width: 100%;
  will-change: opacity, transform;

  &.open {
    opacity: 1;

    &.transitionInFx {
      transform: translateX(100%);
    }
  }

  &.transitionInFx {
    transition: opacity 150ms ease-in;
  }

  &.transitionOutFx {
    transform: translateX(100%);
    transition: opacity 150ms ease-out;
  }
}

.drawer {
  background: $background;
  display: flex;
  flex-flow: column;
  height: 100%;
  position: fixed;
  top: 0;
  touch-action: none;
  transform: translateX(0);
  width: 100%;
  will-change: transform;
  z-index: 100;

  @media screen and (min-width: $medium) {
    width: 50%;
  }

  @media screen and (min-width: $extra-large) {
    width: 30%;
  }

  &.open {
    opacity: 1;
    transform: translateX(100%);
  }

  &.transitionInFx {
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
  }

  &.transitionOutFx {
    transition: transform 150ms cubic-bezier(0.4, 0, 1, 1);
  }

  header {
    align-items: center;
    background: $white;
    border-bottom: solid 1px $grey;
    display: flex;
    font-stretch: condensed;
    font-weight: bold;
    height: 45px;
    justify-content: center;
    position: relative;

    @media screen and (min-width: $extra-large) {
      background-color: transparent;
      font-size: 2rem;
      justify-content: flex-start;
      margin: 0;
      padding: 45px 0;
    }

    button {
      background-color: initial;
      border: initial;
      color: $secondary-text;
      position: absolute;
      left: 15px;

      &:last-of-type {
        left: initial;
        right: 15px;
      }
    }
  }

  section {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;
    overflow: auto;
  }

  footer {
    align-items: center;
    background: $white;
    display: flex;
    flex: 0 0 60px;
  }
}

.directionLeftToRight {
  left: -100%;

  @media screen and (min-width: $medium) {
    left: -50%;
  }

  @media screen and (min-width: $extra-large) {
    left: -30%;
  }
}

.directionRightToLeft {
  right: -100%;

  @media screen and (min-width: $medium) {
    right: -50%;
  }

  @media screen and (min-width: $extra-large) {
    right: -30%;
  }
}

.directionBottomToTop {
  bottom: -100%;

  @media screen and (min-width: $medium) {
    bottom: -50%;
  }

  @media screen and (min-width: $extra-large) {
    bottom: -30%;
  }
}

.directionTopToBottom {
  top: -100%;

  @media screen and (min-width: $medium) {
    top: -50%;
  }

  @media screen and (min-width: $extra-large) {
    top: -30%;
  }
}

.open {
  &.directionLeftToRight {
    transform: translateX(100%);
  }

  &.directionRightToLeft {
    transform: translateX(-100%);
  }

  &.directionBottomToTop {
    transform: translateY(-100%);
  }

  &.directionTopToBottom {
    transform: translateY(100%);
  }
}
