@import "../styleguide/colors.scss";

.root {
  display: flex;
  flex-flow: row nowrap;
  height: 30px;

  ul {
    align-items: center;
    display: flex;
    flex: 0 0 100%;
    flex-flow: row nowrap;
    justify-content: flex-end;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li,
  li a {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 5px;
  }
}

.pageNumbers a {
  color: $default-text;
  text-decoration: none;

  &:focus,
  &:hover {
    color: $primary-color;
  }
}

.pageNumberSelected {
  align-items: center;
  background: $primary-color;
  border-radius: 50%;
  color: $white;
  display: flex;
  flex: 0 0 30px;
  font-stretch: condensed;
  font-weight: bold;
  height: 30px;
  justify-content: center;
  width: 30px;

  &:focus,
  &:hover {
    color: $white;
  }
}

.paginationArrows {
  color: $primary-color;
  padding: 0;
}
