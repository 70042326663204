@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

.searchBar {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  width: 100%;
  z-index: 4;
  @media screen and (min-width: $medium) {
    padding: 4px;
  }

  @media screen and (min-width: $large) {
    margin: 0;
    width: 100%;
  }

  @media screen and (min-width: $extra-large) {
    padding: 0 4px 0 0;
  }
}

.searchPlaceholder {
  overflow: hidden;
  width: 100%;
}

.placeholderText {
  color: $secondary-text;
  overflow: hidden;
  padding-left: 40px;
  padding-right: 60px;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  
  @media screen and (min-width: $medium) {
    padding-left: 50px;
    padding-right: 230px;
  }

  @media screen and (min-width: $large) {
    padding-right: 240px;
  }

  @media screen and (min-width: $extra-large) {
    padding-left: 20px;
    padding-right: 370px;
  }

  @media screen and (min-width: $extra-extra-large) {
    padding-right: 420px;
  }
}

.searchInput {
  border: none;
  width: 100%;
  padding-left: 40px;

  @media screen and (min-width: $medium) {
    padding-left: 50px;
  }

  @media screen and (min-width: $extra-large) {
    padding-left: 20px;
  }
}

.searchInput:focus {
  outline: 0;
}

.searchInput::-ms-clear {
  display: none;
}

.inputContainer {
  align-items: center;
  display: flex;
  position: relative;

  @media screen and (min-width: $extra-large) {
    height: 60px;
  }
}

.searchIcon {
  display: flex;
  color: $secondary-color;
  font-size: 1.25em;
  left: 15px;
  position: absolute;

  @media screen and (min-width: $medium) {
    svg {
      height: 20px;
      width: 20px;
    }
  }

  @media screen and (min-width: $extra-large) {
    left: 16%;
  }
}

.noCategoryPicker {
  padding-left: 25px;

  .searchIcon {
    left: 15px;
  }

  .placeholderText {
    width: auto;
    left: 50px;
    padding: 0;
  }
}

.searchIcons {
  align-items: center;
  justify-content: flex-end;
  color: $white;
  display: flex;
}

.clearIcon {
  flex: 0 0 auto;
  height: 20px;
  width: 20px;
}

.searchIcons svg {
  display: block;
}

// This is needed to make this specific 'search' icon white
.searchIcons svg g g {
  fill: #fff;
}

.searchButton {
  background-color: $secondary-color;
  border: none;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 10px;
  outline: none;
  padding: 13px;

  @media screen and (min-width: $medium) {
    padding: 13px 88px;
  }

  @media screen and (min-width: $extra-large) {
    padding: 17px 92px;
  }
}

.searchButton svg {
  @media screen and (min-width: $medium) {
    display: none;
  }
}

.searchButton span {
  display: none;

  @media screen and (min-width: $medium) {
    display: inline-block;
  }
}

.searchIcons > svg:first-child {
  background-color: $grey;
  border-radius: 50%;
  cursor: pointer;
  padding: 2px;
}

.searchIcons svg:last-child:hover {
  background-color: $secondary-color-dark;
}

.categoryPicker {
  display: none;

  @media screen and (min-width: $extra-large) {
    display: flex;
    padding: 17px 15px;
    border-right: 1px solid $grey;
  }
}
