$primary-color-light: var(--primary-color-light);
$primary-color: var(--primary-color);
$primary-color-dark: var(--primary-color-dark);
$secondary-color-light: var(--secondary-color-light);
$secondary-color: var(--secondary-color);
$secondary-color-dark: var(--secondary-color-dark);
$background: var(--background);
$white: var(--white);
$grey: var(--grey);
$dark-grey: var(--dark-grey);
$default-text: var(--default-text);
$secondary-text: var(--secondary-text);
$dark-text: var(--dark-text);
$succes: var(--succes);
$error: var(--error);
$rating: var(--rating);
$red: var(--red);
