@import "../styleguide/colors.scss";

@mixin transition(
  $transition-property: all,
  $transition-time: 0.2s,
  $method: ease-in-out
) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}

@mixin hover-animation($width, $color) {
  box-shadow: inset 0 0 0 $width $color;
  &:hover {
    box-shadow: inset 0 0 0 2px $color;

    svg {
      color: $color;
    }
  }
}

.share {
  list-style: none;
}

.shareItem {
  margin-right: 5px;
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }

  a {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include transition();

    svg {
      color: $white;
      font-size: 0.875rem;
    }
  }

  .facebook {
    @include hover-animation(30px, #297fca);
  }

  .twitter {
    @include hover-animation(30px, #2aa3ef);
  }

  .googleplus {
    @include hover-animation(30px, #db5149);
  }

  .whatsapp {
    @include hover-animation(30px, #69e281);
  }
  .linkedin {
    @include hover-animation(30px, #0077b5);
  }

  .email {
    box-shadow: inset 0 0 0 30px $primary-color;
    i {
      color: #fff;
    }

    &:hover {
      box-shadow: inset 0 0 0 2px $primary-color;
      i {
        color: $primary-color;
      }
    }
  }
}

.shareLarge {
  .shareItem {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    a {
      width: 45px;
      height: 45px;
      border-radius: 45px;

      i {
        font-size: 18px;
      }
    }
  }
}

.modal {
  .shareItem {
    display: block;

    a {
      width: 100%;
      justify-content: left;
      font-size: 18px;
      margin-left: 5px;
      margin-bottom: 15px;

      i {
        opacity: 1;
        height: 16px;
      }
    }
  }

  .email,
  .linkedin,
  .whatsapp,
  .twitter,
  .facebook {
    box-shadow: none;

    i {
      color: #747474;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.overlay {
  padding: 0 0 0 15px !important;

  li.shareItem {
    margin-bottom: 15px;
    margin-top: 15px;

    a {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.floating {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  li {
    margin-bottom: 10px;
    margin-right: 0;

    &:first-child {
      margin-top: 10px;
    }

    a {
      height: 40px;
      width: 40px;

      i {
        font-size: 18px;
      }
    }
  }

  .facebook {
    @include hover-animation(40px, #297fca);
  }

  .twitter {
    @include hover-animation(40px, #2aa3ef);
  }

  .googleplus {
    @include hover-animation(40px, #db5149);
  }

  .whatsapp {
    @include hover-animation(40px, #69e281);
  }
  .linkedin {
    @include hover-animation(40px, #0077b5);
  }
}
