@import "./styleguide/colors.scss";
@import "./styleguide/vars.scss";

.siabOverlay {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.siabClose {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -35px;
  right: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: $dark-grey;
  color: white;
}

.siabModal {
  width: 100%;
  max-width: 1560px;
  background-color: $background;
  padding: 30px;
  margin: 45px 0;
  position: relative;
}

.siabHeader {
  flex-direction: column;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  
  @media screen and (min-width: $medium) {
    flex-direction: row;
  }

  img {
    margin-right: 30px;
    margin-bottom: 15px;
    max-width: 210px;

    @media screen and (min-width: $medium) {
      flex-direction: row;
      margin-bottom: 0;
    }
  }
}
