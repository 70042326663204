@import "styleguide/breakpoints.scss";
@import "styleguide/typography.scss";
@import "styleguide/colors.scss";

*,
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: normal;
  outline-color: $primary-color;
}

:root {
  --primary-color-light: #6fbdff;
  --primary-color: #3293e6;
  --primary-color-dark: #326eb4;

  --secondary-color-light: #fb915b;
  --secondary-color: #f36a25;
  --secondary-color-dark: #df5713;

  --background: #f7f7f7;

  --white: #ffffff;
  --grey: #e2e2e2;
  --dark-grey: #555555;
  --red: #d0021b;

  --default-text: #2a2a2a;
  --secondary-text: #9b9b9b;
  --dark-text: #4a4a4a;
  --succes: #00c48c;
  --error: #ff4b50;
  --rating: #ffcf5c;
}

#siab-widget code,
#siab-widgetOverlay code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#siab-widget svg,
#siab-widgetOverlay svg {
  fill: currentColor;
}

#siab-widget strong,
#siab-widget b,
#siab-widgetOverlay strong,
#siab-widgetOverlay b {
  font-stretch: condensed;
  font-weight: bold;
}

#siab-widget {
  display: flex;
  flex-flow: column;
}
