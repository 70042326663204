@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

.root {
  background: $white;
  border-radius: 4px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  flex: 0 0 100%;
  max-height: 0;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;

  &_opened {
    overflow: initial;
    max-height: initial;
  }

  @media screen and (min-width: $small) {
    margin-bottom: 30px;
  }
}

.shareButtonSelected {
  color: $primary-color !important;
}

.shareBtn {
  position: relative;

  .share {
    background-color: $white;
    bottom: -75px;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    left: -61px;
    padding: 15px 15px;
    position: absolute;
    width: 175px;
    &:last-child {
      margin-right: 0;
    }

    a {
      margin-left: 0;
      margin-right: 5px;
    }
  }
}

.header {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  padding: 20px 30px 30px 30px;
}

.headerH2 {
  color: $dark-grey;
  flex: 1 1 100%;
  font-stretch: condensed;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.actionBtns {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;

  a,
  button {
    background: none;
    border: none;
    color: $secondary-text;
    cursor: pointer;
    font-size: 1.3rem;
    margin-left: 30px;
    outline: 0;

    &:hover {
      color: $dark-grey;
    }
  }
}

.likedProductIcon {
  color: $error;
}

.productImage {
  align-items: flex-start;
  display: flex;
  height: 87px;
  justify-content: center;
  object-fit: contain;
  overflow: hidden;
  width: 100%;

  @media screen and (min-width: $medium) {
    height: 142px;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.productName {
  color: $dark-grey;
  display: inline-block;
  font-size: 0.875rem;
  font-stretch: condensed;
  margin-top: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.productPrice span {
  font-stretch: condensed;
  font-weight: bold;
}

.productPriceCurrency {
  font-size: 0.875rem;
  margin-right: 5px;
}

.productShop {
  color: $secondary-color;
  font-stretch: condensed;
  font-weight: bold;
}

.galleryAndDetails {
  display: flex;
  flex-flow: row nowrap;
  padding: 0 30px 0 30px;
}

.gallery {
  flex: 0 0 50%;
}

.details {
  display: flex;
  flex-flow: column;
  flex: 0 0 50%;
}

.shopLogoAndPrice,
.price {
  display: flex;
  flex-flow: row nowrap;
}

.shopLogoAndPrice {
  align-items: center;
}

.shopLogo {
  width: 175px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.price {
  align-items: flex-end;
  color: $dark-grey;
  margin-left: 30px;
}

.priceCurrency,
.priceValue {
  font-size: 2.25rem;
  font-stretch: condensed;
  font-weight: bold;
  line-height: 1.85rem;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shops {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  margin-left: -10px;
  width: calc(100% + 20px);
  transition: height 250ms ease-in;

  @media screen and (min-width: $medium) {
    padding: 0 30px;
    margin-bottom: 20px;
    margin-left: 0;
    width: 100%;
  }
}

.shopsTable {
  margin-top: 30px;
  width: 100%;

  tbody:not(:hover) {
    tr:first-child {
      td:first-child {
        color: $white;

        @media screen and (min-width: $medium) {
          font-stretch: condensed;
          font-weight: bold;
        }
      }

      .ribbon {
        transform: scale(1);
      }

      .ribbon + a span {
        color: white;
      }
    }
  }

  tbody tr {
    cursor: pointer;
    overflow: hidden;

    &:nth-child(odd) {
      background: $background;
    }

    &:hover {
      td:first-child {
        color: $white;

        @media screen and (min-width: $medium) {
          font-stretch: condensed;
          font-weight: bold;
        }
      }

      .ribbon {
        transform: scale(1);
      }

      .ribbon + a span {
        color: white;
      }
    }
  }

  th {
    font-stretch: condensed;
    font-weight: bold;
    padding: 0px 15px 15px;
  }

  td {
    height: 45px;
    position: relative;
    transition: color 250ms ease-in-out;
    vertical-align: middle;

    a {
      color: inherit;
      display: block;
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      text-decoration: none;
      width: 100%;
    }

    span {
      position: relative;
      z-index: 2;
    }
  }
}

.shopNameHeading,
.shopNameColValue {
  text-align: left;

  @media screen and (min-width: $medium) {
    width: 50%;
  }
}

.deliveryTimeHeading,
.deliveryPriceHeading,
.deliveryTimeColValue,
.deliveryPriceColValue {
  text-align: left;

  @media screen and (min-width: $medium) {
    text-align: center;
  }
}

.deliveryTimeColValue,
.deliveryPriceColValue {
  font-size: 0.875rem;
  white-space: nowrap;
}

.priceHeading,
.priceColValue {
  text-align: right;
}

.priceColValue {
  white-space: nowrap;
}

.ribbon {
  background-color: $secondary-color;
  display: flex;
  flex-direction: row;
  height: 100%;
  left: 0px;
  line-height: 45px;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform-origin: top left;
  transform: scale(0, 1);
  transition: transform 250ms ease-in-out;
  width: 200px;

  @media screen and (min-width: $medium) {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
}

.arrow {
  position: absolute;
  right: -20px;
  fill: $secondary-color;
}

.shopTableCurrency {
  font-size: 1rem;
}

.shopTablePrice {
  font-size: 1rem;
}

.priceColValueGratis {
  color: $succes;
  font-stretch: condensed;
  font-weight: bold;
}

.scroll {
  overflow: auto;
}

.productDrawer {
  padding-bottom: 30px;

  header {
    background-color: transparent;
    margin-bottom: 30px;
    flex-direction: column;
    height: initial;
    align-items: start;
    padding: 10px 40px 10px 0;

    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    button {
      font-size: 1.25em;
      top: 15px;
    }

    .brand {
      color: $primary-color;
      text-decoration: none;
    }
  }

  main {
    position: relative;
  }

  .buttons {
    display: flex;
    font-size: 1.2em;
    justify-content: flex-end;
    padding-right: 15px;
    position: relative;

    .wishlistBtn {
      background-color: initial;
      border: none;
      font-size: 1.1em;
      margin-left: 15px;
    }

    .share {
      background-color: $background;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 25px;
      left: -10px;
      right: -10px;
      padding: 5px 15px 5px 0;
    }

    .activeShare {
      color: $primary-color;
    }
  }

  .details {
    .shopLogo {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
      width: 175px;
    }

    .price {
      align-items: baseline;
    }
    .priceCurrency {
      font-size: 1rem;
    }

    .priceValue {
      font-size: 1.5rem;
    }
  }

  .shopNameColValue a span {
    color: $primary-color;
  }
}

.drawerBackground {
  background-color: $white;
}

.originalPrice {
  color: $grey;
  font-size: 1rem;
  margin-right: 15px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: calc(100% + 10px);
    border-bottom: 1px solid $red;
    transform: rotate(-10deg);
    bottom: 7px;
    left: -10px;

    @media screen and (max-width: 767px) {
      bottom: 10px;
    }
  }
}

.originalCurrency {
  color: $grey;
  font-size: 1rem;
}

.allShopsBtnShown {
  margin-bottom: 64px;
}

.showMoreShops {
  position: absolute;
  left: 50%;
  cursor: pointer;
  transform: translateX(-50%);
  text-align: center;
  line-height: 1.7;
  font-size: 16px;
  bottom: -34px;
  height: 34px;
  padding: 0 10px;
  background-color: #fff;
  box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.1);

  &:before,
  &:after {
    content: "";
    position: fixed;
    background-color: #fff;
    height: 34px;
    width: 15px;
  }

  &:before {
    left: -10px;
    transform: skew(15deg);
    border-bottom-left-radius: 6px;
    box-shadow: -9px 7px 9px 0 rgba(0, 0, 0, 0.1);
  }

  &:after {
    right: -10px;
    transform: skew(-15deg);
    border-bottom-right-radius: 6px;
    box-shadow: 9px 7px 9px 0 rgba(0, 0, 0, 0.1);
  }

  &ButtonArrow {
    margin-left: 10px;
    color: var(--color_primary);
  }
}
