@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

.rootCategories {
  list-style-type: none;
  margin-left: 15px;
  padding: 0;

  li {
    display: flex;
    flex-flow: column;
    flex: 0 0 100%;

    &.toggleCategoriesBtnContainer {
      flex-flow: row nowrap;
    }
  }

  a {
    align-items: center;
    color: $default-text;
    display: flex;
    flex: 0 0 100%;
    flex-flow: row nowrap;
    padding: 5px 0;
    text-decoration: none;

    &.filterCategorySelected {
      color: $primary-color;
    }

    &:hover {
      color: $primary-color;
      text-decoration: underline;

      .filtersCategoryDocsCount {
        color: $primary-color;
      }
    }
  }
}

.categoryName {
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.categoryDocsCount {
  color: $dark-grey;
  flex: 0 0 auto;
  font-size: 0.75rem;
}

.subCategories {
  margin-left: 15px;
}

.subSubCategories {
  margin-left: 15px;
}

.toggleCategoriesBtn {
  align-items: center;
  background: none;
  border: none;
  color: $primary-color;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
  outline: none;
  width: auto;

  &:hover {
    text-decoration: underline;
  }
}

.toggleDirectionIcon {
  font-size: 0.875rem;
  margin-left: 5px;
}
