@import "../styleguide/colors";
@import "../styleguide/vars";

.categories,
.price,
.filters {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.title {
  background-color: $background;
  height: 1.4em;
  margin-bottom: 15px;
  width: 100%;
}

.category {
  background-color: $background;
  height: 1.4em;
  margin-bottom: 15px;
  margin-left: 15px;
}

.item {
  background-color: $background;
  height: 1.4em;
  margin-bottom: 15px;
  padding: 5px 0;
  width: 100%;
}

.priceGraph {
  background-color: $background;
  height: 109px;
  margin-bottom: 15px;
}

.priceBox {
  background-color: $background;
  height: 44px;
  width: 100%;
}

.searchBox {
  background-color: $background;
  height: 48px;
  width: 100%;
}
