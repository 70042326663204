@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

.root {
  list-style-type: none;
}

.searchBoxContainer {
  border-bottom: solid 1px $dark-grey;
  margin-bottom: 15px;

  span:first-child {
    position: absolute;
    top: 54px;
    left: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-size: 1.125rem;
  }
}

.searchBox {
  appearance: none;
  background: transparent;
  font-size: 1.125rem;
  padding-left: 25px !important;
  padding-right: 0 !important;

  &:focus,
  &:hover {
    border: 0 !important;
  }
}

.searchIconContainer {
  font-size: 1.125rem;
  left: 0 !important;
}

.searchIcon {
  color: $primary-color;
}

.itemName {
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itemDocs {
  color: $dark-grey;
  flex: 0 0 auto;
  font-size: 0.75rem;
}

.toggleCategoriesBtn {
  align-items: center;
  background: none;
  border: none;
  color: $primary-color;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
  outline: none;
  width: auto;

  &:hover {
    text-decoration: underline;
  }

  a {
    display: flex;
    align-items: center;
    svg {
      height: 14px;
    }
  }
}

.toggleDirectionIcon {
  font-size: 0.875rem;
  margin-left: 5px;
}

.filterShowAll {
  header {
    background: transparent;
    border-bottom: 0;
    margin-bottom: 15px;

    button {
      color: $default-text;
      font-size: 0.875rem;
    }
  }
}

.scroll {
  overflow: scroll;
}
