@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";
@import "../styleguide/fonts.scss";

.suggestionBox {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  margin-top: 15px;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;

  .suggestionType:first-child.first {
    border: none;
  }
}

.query {
  color: $default-text;
  margin-right: 4px;
}

.suggestionType {
  position: relative;

  &:first-child {
    .link {
      &:before {
        content: "";
        position: absolute;
        top: -9px;
        width: 0;
        height: 0;
        left: 15px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;

        @media screen and (min-width: $extra-large) {
          left: 15.5%;
        }
      }

      &:focus,
      &:hover {
        background-color: $primary-color;
        color: $white;

        &:before {
          border-bottom: 10px solid $primary-color;
        }

        ~ span {
          color: $white;
        }

        span {
          color: $white;
        }
      }
    }
  }

  &:hover,
  &:focus {
    background-color: $primary-color;
    color: $white;

    a {
      color: $white;
    }

    span {
      color: $white;
    }
  }
}

.in {
  color: $primary-color;
}

.link {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  flex: 0 0 100%;
  font-family: "TT Prosto Sans Condensed", sans-serif;
  padding: 5px 15px;
  overflow: hidden;
  text-decoration: none;
  width: 100%;

  @media screen and (min-width: $medium) {
    padding: 10px 15px;
  }
}

.suggestionName {
  font-weight: bold;
  font-stretch: condensed;
  line-height: 1.125rem;
  position: absolute;
  right: 15px;
  top: 5px;
  @media screen and (min-width: $medium) {
    top: 10px;
  }
}

.selected {
  background-color: $primary-color;
  color: $white;

  a,
  span {
    color: $white;
  }
}

.suggestionType.selected:first-child a:before {
  border-bottom: 10px solid $primary-color;
}

.first {
  border-top: 1px solid $grey;
}
