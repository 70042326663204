@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

.root {
  background: $white;
  border-radius: 4px;
  box-shadow: 0 0 0 transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 15px;
  position: relative;
  transition: box-shadow 350ms;
  text-decoration: none;
  margin-bottom: 20px;
  &:hover,
  &.selected {
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);

    .wishlistBtn {
      opacity: 1;
    }
  }

  @media screen and (min-width: $small) {
    margin-bottom: 30px;
  }
}

.column {
  width: calc(1/1*100% - (1 - 1/1)*20px);
  max-width: calc(1/1*100% - (1 - 1/1)*20px);
  
  @media screen and (min-width: $medium) {
    width: calc(1/2*100% - (1 - 1/2)*20px);
    max-width: calc(1/2*100% - (1 - 1/2)*20px);
    margin-right: 20px;
    &:nth-child(2n) {
      margin-right: 0px;
    }
  }
}

.tile {
  width: calc(1/2*100% - (1 - 1/2)*20px);
  max-width: calc(1/2*100% - (1 - 1/2)*20px);
  margin-right: 20px;
  &:nth-child(2n) {
    margin-right: 0;
  }

  @media screen and (min-width: $medium) {
    width: calc(1/3*100% - (1 - 1/3)*30px);
    max-width: calc(1/3*100% - (1 - 1/3)*30px);
    margin-right: 30px;
    &:nth-child(2n) {
      margin-right: 30px;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media screen and (min-width: $extra-large) {
    width: calc(1/5*100% - (1 - 1/5)*30px);
    max-width: calc(1/5*100% - (1 - 1/5)*30px);
    &:nth-child(2n) {
      margin-right: 30px;
    }
    &:nth-child(3n) {
      margin-right: 30px;
    }
    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}

.discount {
  background: $red;
  border-radius: 4px;
  color: $white;
  left: 10px;
  padding: 2px 5px;
  position: absolute;
  top: -5px;
}

.selected:after {
  box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 10px solid $white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -30px;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: -1;
}

.wishlistBtn {
  background: none;
  border: none;
  color: $secondary-text;
  cursor: pointer;
  opacity: 0;
  outline: none;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: opacity 350ms;

  &:hover {
    color: $default-text;
  }

  &.wishlistSelectedBtn {
    color: $error;
    opacity: 1;
  }
}

.productImage {
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 87px;
  justify-content: center;
  object-fit: contain;
  overflow: hidden;
  width: 100%;

  @media screen and (min-width: $medium) {
    height: 142px;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.productName {
  color: $dark-grey;
  font-size: 0.875rem;
  font-stretch: condensed;
  margin-top: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.productPrice span {
  color: $default-text;
  font-stretch: condensed;
  font-weight: bold;
}

.productShop {
  color: $secondary-color;
  font-stretch: condensed;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title {
  color: $default-text;
  font-size: 1.5rem;
  font-stretch: condensed;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  flex-grow: 1;
  display: flex;

  .productDescription {
    color: $default-text;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 15px;
    width: 60%;

    @media screen and (min-width: $medium) {
      height: 134px;
      -webkit-line-clamp: 6;
    }

    @media screen and (min-width: $extra-large) {
      margin-bottom: 30px;
    }

    * {
      font-weight: normal;
      font-size: 1rem;
      font-stretch: normal;
    }

    br {
      display: none;
    }
  }

  .productImage {
    display: inline-block;
    width: 40%;
    flex-grow: initial;
    text-align: center;
  }
}