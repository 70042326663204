@import "../styleguide/colors.scss";
@import "../styleguide/vars.scss";

:global {
  @import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";

  .image-gallery {
    width: 100%;
  }

  .image-gallery-content {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;

    @media screen and (max-width: $extra-large) {
      flex-direction: column;
      align-items: unset;
    }
  }

  .main-product-gallery {
    .image-gallery-content {
      flex-direction: column !important;
    }

    .image-gallery-thumbnails-container {
      flex-direction: row !important;
    }
  }

  .image-gallery-slides,
  .image-gallery-swipe,
  .image-gallery-slide {
    height: 100%;
  }

  .image-gallery-swipe {
    flex: 1 1 100%;
    order: 2;
  }

  .image-gallery-slide {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > div {
      flex: 0 0 auto;
    }
  }

  .image-gallery-slides {
    margin: 0;
  }

  .image-gallery-image {
    position: relative;
    width: 100%;
    flex: 1;
    min-height: 300px;
    height: auto;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 100%;
      max-height: 100%;
      margin: 15px auto 0;
      width: auto;
      height: auto;
    }
  }

  .image-gallery-slide-wrapper {
    flex: 1;

    &.bottom {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0 15px;
    }
  }
  .image-gallery-thumbnails-wrapper {
    max-width: 290px;
    margin: 0 auto;
  }

  .image-gallery-thumbnails-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    @media screen and (max-width: $extra-large) {
      flex-direction: row;
    }
  }

  .image-gallery-thumbnail {
    border: solid 1px transparent;
    border-radius: 4px;
    padding: 7px;
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;

    &:only-child {
      display: none;
    }

    img {
      display: block;
      max-height: 55px;
      max-width: 55px;
      width: auto;
      height: auto;
    }

    @media screen and (max-width: $extra-large) {
      padding: 0;
      margin: 5px;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      border: solid 2px $white;
      background: $grey;

      img {
        display: none;
      }
    }

    &.active {
      border: solid 1px $primary-color;

      @media screen and (max-width: $extra-large) {
        background: $primary-color;
        border: solid 2px $primary-color;
      }
    }
  }
}

.imageGalleryRightNav,
.imageGalleryLeftNav {
  flex: 0 0 auto;
  opacity: 0.5;
  transition: opacity 0.17s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.imageGalleryLeftNav {
  order: 1;
}

.imageGalleryRightNav {
  order: 3;
}
