@import '../styleguide/colors.scss';
@import '../styleguide/vars.scss';

.noResult {
  align-items: center;
  border-bottom: 1px solid $grey;
  display: flex;
  flex-direction: column;
  width: 100%;
  
  @media screen and (min-width: $medium) {
    flex-direction: row;
    margin-top: 15px;
  }

  svg {
    font-size: 10em;

    @media screen and (min-width: $medium) {
      font-size: 15rem;
    }
  }

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $medium) {
      align-items: flex-start;
      margin-left: 30px;
    }
  }

  h1 {
    font-weight: bold;
    font-stretch: condensed;
  }

  .searchTerm {
    color: $primary-color;
  }

  ul {
    margin: 30px 0;
    list-style: none;
  }

  li:first-of-type {
    font-weight: bold;
    font-stretch: condensed;
    margin-bottom: 5px;
    text-align: center;

    @media screen and (min-width: $medium) {
      text-align-last: left;
    }
  }

  li:not(:first-of-type) {
    padding-left: 20px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      display: block;
      transform: rotate(45deg);
      width: 6px;
      height: 2px;
      background-color: $succes;
      left: 2px;
      top: 13px;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      transform: rotate(45deg);
      width: 2px;
      height: 10px;
      background-color: $succes;
      left: 9px;
      top: 7px;
    }
  }
}

.categories {
  margin-top: 30px;
  width: 100%;

  h3 {
    font-weight: bold;
    font-stretch: condensed;
    font-size: 1.125em;
    margin-bottom: 15px;
  }

  .categoryCard {
    align-items: center;
    background-color: $white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: 15px 15px 0;
    text-decoration: none;

    &:hover {
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);

      p {
        color: $primary-color;
      }
    }

    @media screen and (min-width: $large) {
      padding: 30px 15px 15px;
    }

    p {
      color: $secondary-text;
      margin-bottom: 15px;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      @media screen and (min-width: $large) {
        margin-bottom: 0;
      }
    }

    img {
      margin-bottom: 15px;
      max-height: 50px;
      width: 50px;

      @media screen and (min-width: $large) {
        max-height: 60px;
        width: 60px;
        margin-bottom: 30px;
      }
    }
  }
}
